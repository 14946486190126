import React from "react";
import HtmlReactParser from "html-react-parser";
import {
  FloorplanTabpanel,
  GalleryItem,
  GalleryTabpanel,
  HeroContainer,
  HeroInfoItem,
  HeroTablist,
  HeroTabpanel,
  HeroTabpanels,
  InfoTabpanel,
  SubtitleText,
  TourTabpanel,
  VideoBlockHeading,
} from "raffle-house-uikit";
import { heroGalleryImages, heroInfoData } from "../../lib/static-data";
import "./styles.css";

const withUrl = (imageName) =>
  `${process.env.REACT_APP_AWS_S3_URL}gallery/home/Warwickshire/${imageName}.jpg`;

export default function Hero() {
  return (
    <HeroContainer>
      <HeroTablist />
      <HeroTabpanels>
        <HeroTabpanel id="tour">
          <TourTabpanel
            video={
              process.env.REACT_APP_AWS_S3_URL +
              "videos/01.03.2025/competition-01.03.2025.mp4"
            }
            tabletVideo={
              process.env.REACT_APP_AWS_S3_URL +
              "videos/01.03.2025/competition-01.03.2025.mp4"
            }
            mobileVideo={
              process.env.REACT_APP_AWS_S3_URL +
              "videos/01.03.2025/competition-mobile-01.03.2025.mp4"
            }
            poster={
              process.env.REACT_APP_AWS_S3_URL +
              "videos/01.03.2025/preview-competition-01.03.2025.jpg"
            }
            tabletPoster={
              process.env.REACT_APP_AWS_S3_URL +
              "videos/01.03.2025/preview-competition-01.03.2025.jpg"
            }
            mobilePoster={
              process.env.REACT_APP_AWS_S3_URL +
              "videos/preview-mobile-competition-video-01-11-2024.jpg"
            }
          >
            <div className="video-block-badge">
              <svg
                width="875"
                height="875"
                viewBox="0 0 875 875"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M875.003 437.502C875.003 679.127 679.127 875.003 437.502 875.003C195.876 875.003 0 679.127 0 437.502C0 195.876 195.876 0 437.502 0C679.127 0 875.003 195.876 875.003 437.502Z"
                  fill="#043B3B"
                />
                <circle cx="436.219" cy="436.19" r="338.774" stroke="white" />
                <path
                  d="M244.602 468.783C254.468 468.783 257.991 465.666 259.479 457.112L260.653 450.157H244.602V425.534H264.803L266.134 417.859C270.832 389.959 290.407 373.97 329.87 373.97C335.038 373.97 340.519 374.21 346.313 374.77V403.23C342.868 402.83 338.561 402.59 335.351 402.59C314.053 402.59 307.32 409.545 304.657 423.375L304.266 425.534H326.033V450.157H299.49C298.159 456.632 296.514 460.709 291.895 464.706H355.709V492.687H244.602V468.783Z"
                  fill="white"
                />
                <path
                  d="M365.493 492.687V468.304L399.788 439.364C411.298 429.691 421.477 420.977 421.477 409.945C421.477 404.589 419.05 400.352 413.569 400.352C407.853 400.352 404.486 404.429 404.486 413.542C404.486 414.822 404.486 416.181 404.643 417.699H362.204C363.301 388.6 384.207 375.17 414.665 375.17C441.443 375.17 462.819 386.761 462.819 411.464C462.819 432.089 447.316 444.001 428.681 455.913L414.273 465.106H463.289V492.687H365.493Z"
                  fill="white"
                />
                <path
                  d="M493.509 494.685C482.078 494.685 473.778 486.131 473.778 475.019C473.778 463.907 482.078 455.513 493.509 455.513C504.863 455.513 513.241 463.907 513.241 475.019C513.241 486.131 504.863 494.685 493.509 494.685Z"
                  fill="white"
                />
                <path
                  d="M575.016 495.165C542.443 495.165 522.634 483.093 522.634 460.629C522.634 444.081 532.578 436.166 545.419 432.409C534.065 428.332 524.748 421.137 524.748 406.268C524.748 386.442 544.323 375.649 575.016 375.649C605.631 375.649 625.519 386.442 625.519 406.268C625.519 421.137 615.966 428.332 604.691 432.409C617.454 436.166 627.476 444.081 627.476 460.629C627.476 483.093 607.667 495.165 575.016 495.165ZM564.68 458.551C564.68 466.785 568.674 471.581 575.016 471.581C581.436 471.581 585.43 466.785 585.43 458.551C585.43 450.316 581.436 445.52 575.016 445.52C568.674 445.52 564.68 450.316 564.68 458.551ZM565.307 410.585C565.307 417.62 569.143 421.937 575.016 421.937C580.967 421.937 584.803 417.62 584.803 410.585C584.803 403.47 580.967 399.153 575.016 399.153C569.143 399.153 565.307 403.47 565.307 410.585Z"
                  fill="white"
                />
                <path
                  d="M186 608.038V529.875H223.957L235.435 575.824L247.02 529.875H283.856V608.038H257.804V560.671L245.952 608.038H224.064L212.052 561.434V608.038H186Z"
                  fill="white"
                />
                <path
                  d="M295.065 608.038V529.875H323.36V608.038H295.065Z"
                  fill="white"
                />
                <path
                  d="M334.515 608.038V529.875H362.81V586.78H395.375V608.038H334.515Z"
                  fill="white"
                />
                <path
                  d="M403.648 608.038V529.875H431.943V586.78H464.508V608.038H403.648Z"
                  fill="white"
                />
                <path
                  d="M472.781 608.038V529.875H501.075V608.038H472.781Z"
                  fill="white"
                />
                <path
                  d="M553.285 610C528.407 610 509.882 597.354 509.882 568.847C509.882 539.959 529.261 527.913 553.285 527.913C577.308 527.913 596.687 539.632 596.687 568.847C596.687 597.354 578.163 610 553.285 610ZM540.525 568.847C540.525 581.384 543.141 592.013 553.285 592.013C563.428 592.013 566.044 581.384 566.044 568.847C566.044 556.42 563.428 545.9 553.285 545.9C543.141 545.9 540.525 556.42 540.525 568.847Z"
                  fill="white"
                />
                <path
                  d="M605.494 608.038V529.875H640.675L659.521 571.736V529.875H686V608.038H652.741L631.973 562.197V608.038H605.494Z"
                  fill="white"
                />
                <path
                  d="M394.76 342.087C369.883 342.087 351.358 329.442 351.358 300.935C351.358 272.046 370.737 260 394.76 260C418.784 260 438.163 271.719 438.163 300.935C438.163 329.442 419.638 342.087 394.76 342.087ZM382.001 300.935C382.001 313.471 384.617 324.1 394.76 324.1C404.904 324.1 407.52 313.471 407.52 300.935C407.52 288.507 404.904 277.987 394.76 277.987C384.617 277.987 382.001 288.507 382.001 300.935Z"
                  fill="white"
                />
                <path
                  d="M446.97 340.125V261.962H486.636C509.004 261.962 519.308 268.776 519.308 283.056C519.308 295.484 511.834 301.262 503.078 303.66C514.076 305.786 519.521 309.383 519.521 320.939V340.125H491.12V320.121C491.12 314.289 487.49 311.618 480.71 311.618H475.264V340.125H446.97ZM475.264 295.484H480.977C487.169 295.484 491.44 293.304 491.44 286.817C491.44 280.985 487.436 278.859 480.977 278.859H475.264V295.484Z"
                  fill="white"
                />
                <path
                  d="M83.5544 412.133L32.021 408.495L30.5543 429.338L24.7832 428.931L28.1922 380.486L33.9633 380.894L32.4966 401.737L84.03 405.376L83.5544 412.133Z"
                  fill="white"
                />
                <path
                  d="M90.297 365.639C89.7278 368.299 88.7109 370.585 87.2463 372.497C85.74 374.344 83.953 375.685 81.8851 376.521C79.8173 377.357 77.668 377.536 75.4371 377.057C72.5688 376.441 70.465 375.238 69.1257 373.448C67.7979 371.605 67.1473 368.933 67.174 365.433C67.212 361.88 67.8572 357.177 69.1095 351.324L69.8779 347.733L74.1007 348.639L73.3493 352.151C72.6549 355.396 72.1805 358.133 71.9263 360.36C71.672 362.587 71.6738 364.396 71.9317 365.787C72.1897 367.178 72.6867 368.23 73.4227 368.945C74.1587 369.659 75.1641 370.154 76.439 370.427C78.6167 370.895 80.5555 370.532 82.2553 369.339C83.9665 368.092 85.101 366.165 85.6588 363.558C86.1142 361.43 86.0081 359.459 85.3404 357.647C84.6841 355.781 83.5762 354.207 82.0169 352.926C80.469 351.592 78.553 350.68 76.269 350.19L67.186 348.24C63.8927 347.533 61.3867 347.69 59.668 348.713C57.8961 349.724 56.6857 351.745 56.0368 354.778C55.5359 357.119 55.3973 359.454 55.6211 361.784C55.7917 364.102 56.3759 366.565 57.3737 369.172L52.1947 370.398C51.5346 368.809 51.0471 367.063 50.7323 365.159C50.3643 363.243 50.1994 361.288 50.2374 359.293C50.2754 357.298 50.4822 355.422 50.8579 353.666C51.5751 350.314 52.7248 347.667 54.307 345.725C55.8361 343.772 57.83 342.503 60.2886 341.917C62.694 341.321 65.6231 341.393 69.0757 342.134L93.4563 347.369L92.1585 353.434L83.1552 351.501L83.3089 350.783C85.2363 351.642 86.7861 352.837 87.9584 354.369C89.1421 355.847 89.9445 357.55 90.3656 359.477C90.7866 361.404 90.7638 363.458 90.297 365.639Z"
                  fill="white"
                />
                <path
                  d="M96.8492 333.504L82.127 309.172L85.3415 310.348L59.9879 318.971L62.6723 311.611L82.0842 305.246L81.3012 307.393L70.5856 289.915L73.2421 282.632L86.9501 305.463L84.1182 304.426L110.774 295.324L108.09 302.684L87.2431 309.654L88.054 307.431L99.5336 326.144L96.8492 333.504Z"
                  fill="white"
                />
                <path
                  d="M98.6965 276.49L93.8435 273.985L104.295 253.67L109.148 256.175L98.6965 276.49Z"
                  fill="white"
                />
                <path
                  d="M136.42 247.202L107.61 228.248L103.315 234.798L99.0245 231.975L104.303 223.924L104.749 226.366L102.161 224.664C97.7565 221.766 95.1349 218.479 94.296 214.802C93.487 211.08 94.4522 206.833 97.1917 202.06L98.8 199.309L103.336 201.61L101.483 204.882C100.396 206.837 99.8058 208.629 99.7115 210.26C99.6172 211.891 100.027 213.397 100.94 214.779C101.854 216.161 103.241 217.465 105.103 218.689L107.964 220.571L106.405 220.718L113.204 210.348L117.495 213.171L111.233 222.722L140.043 241.675L136.42 247.202Z"
                  fill="white"
                />
                <path
                  d="M154.107 221.379L123.254 196.511L127.289 191.487L134.59 197.372L134.079 198.008C132.161 195.274 131.282 192.506 131.443 189.702C131.561 186.864 132.689 183.897 134.827 180.801L136.118 178.803L140.869 182.004L138.645 185.554C136.583 188.641 135.726 191.617 136.074 194.482C136.414 197.271 137.96 199.773 140.711 201.991L158.296 216.164L154.107 221.379Z"
                  fill="white"
                />
                <path
                  d="M189.834 182.14C186.841 185.067 183.687 187.01 180.371 187.97C177.057 188.854 173.735 188.794 170.405 187.792C167.114 186.751 163.988 184.712 161.029 181.675C158.145 178.716 156.224 175.576 155.265 172.255C154.268 168.895 154.174 165.604 154.981 162.381C155.827 159.121 157.591 156.179 160.274 153.557C162.839 151.048 165.586 149.465 168.513 148.808C171.441 148.151 174.396 148.379 177.379 149.493C180.401 150.569 183.316 152.547 186.124 155.429L187.888 157.239L165.555 179.075L162.538 175.979L181.723 157.222L181.529 158.894C178.494 155.779 175.442 154.049 172.373 153.703C169.304 153.281 166.449 154.363 163.805 156.947C161.823 158.886 160.587 161.006 160.098 163.309C159.61 165.535 159.813 167.808 160.707 170.127C161.563 172.408 163.035 174.619 165.122 176.761L165.463 177.111C167.778 179.487 170.101 181.131 172.433 182.044C174.804 182.92 177.165 183.045 179.516 182.419C181.829 181.754 184.035 180.396 186.135 178.343C187.806 176.709 189.173 174.879 190.235 172.852C191.297 170.749 191.94 168.409 192.163 165.834L197.04 167.567C196.896 169.989 196.174 172.52 194.874 175.159C193.575 177.799 191.895 180.126 189.834 182.14Z"
                  fill="white"
                />
                <path
                  d="M228.084 149.701C224.708 152.175 221.311 153.653 217.894 154.135C214.488 154.541 211.207 154.012 208.052 152.549C204.941 151.053 202.134 148.593 199.632 145.168C197.194 141.83 195.735 138.45 195.254 135.027C194.74 131.56 195.111 128.288 196.364 125.212C197.661 122.104 199.822 119.441 202.847 117.224C205.741 115.103 208.683 113.925 211.674 113.688C214.665 113.452 217.558 114.096 220.354 115.62C223.194 117.112 225.801 119.483 228.174 122.733L229.666 124.775L204.479 143.234L201.929 139.742L223.565 123.886L223.138 125.513C220.572 122 217.794 119.856 214.804 119.08C211.827 118.229 208.847 118.895 205.866 121.08C203.63 122.719 202.108 124.643 201.299 126.854C200.502 128.989 200.383 131.267 200.941 133.69C201.467 136.069 202.612 138.466 204.376 140.881L204.665 141.277C206.622 143.956 208.69 145.912 210.87 147.146C213.094 148.348 215.414 148.805 217.83 148.518C220.213 148.187 222.589 147.154 224.956 145.419C226.841 144.038 228.452 142.419 229.789 140.563C231.137 138.631 232.104 136.406 232.687 133.888L237.271 136.293C236.787 138.671 235.715 141.074 234.057 143.503C232.398 145.932 230.407 147.998 228.084 149.701Z"
                  fill="white"
                />
                <path
                  d="M293.601 111.757C289.982 113.382 286.504 113.991 283.166 113.583C279.827 113.174 276.806 111.878 274.101 109.694C271.424 107.438 269.24 104.424 267.55 100.651C265.838 96.8277 265.053 93.2143 265.196 89.8104C265.388 86.3843 266.464 83.3363 268.424 80.6665C270.384 77.9966 273.173 75.8488 276.791 74.2229C279.022 73.2207 281.405 72.6268 283.942 72.4412C286.479 72.2556 288.788 72.5598 290.87 73.3537L290.756 78.6829C288.696 77.9386 286.654 77.6335 284.63 77.7676C282.656 77.8795 280.901 78.2806 279.364 78.971C275.598 80.6637 273.232 83.1581 272.267 86.4543C271.28 89.7009 271.775 93.5335 273.754 97.9522C275.689 102.272 278.239 105.241 281.405 106.86C284.549 108.429 288.004 108.367 291.771 106.674C293.308 105.984 294.774 104.937 296.17 103.535C297.616 102.11 298.735 100.355 299.528 98.2689L303.577 101.727C302.784 103.813 301.462 105.779 299.61 107.625C297.785 109.399 295.782 110.777 293.601 111.757Z"
                  fill="white"
                />
                <path
                  d="M330.695 97.748C328.09 98.52 325.6 98.7191 323.223 98.3454C320.884 97.904 318.853 96.9739 317.13 95.555C315.408 94.1361 314.223 92.3309 313.576 90.1395C312.744 87.3219 312.792 84.8959 313.72 82.8615C314.701 80.8116 316.732 78.9612 319.813 77.3101C322.947 75.6436 327.378 73.9611 333.108 72.2626L336.624 71.2203L337.85 75.3685L334.412 76.3875C331.234 77.3294 328.608 78.2213 326.534 79.063C324.46 79.9048 322.875 80.7719 321.779 81.6642C320.683 82.5566 319.998 83.4974 319.724 84.4866C319.45 85.4759 319.497 86.5967 319.867 87.8489C320.499 89.9882 321.745 91.52 323.604 92.4443C325.516 93.3531 327.748 93.4292 330.3 92.6726C332.384 92.055 334.06 91.0189 335.33 89.5643C336.652 88.0942 337.502 86.367 337.878 84.3827C338.307 82.3829 338.19 80.2612 337.528 78.0175L334.892 69.0952C333.936 65.8602 332.599 63.7313 330.88 62.7085C329.146 61.6335 326.795 61.5361 323.826 62.4162C321.534 63.0956 319.42 64.091 317.485 65.4024C315.534 66.6616 313.655 68.3537 311.846 70.4786L308.294 66.5094C309.371 65.1687 310.669 63.9045 312.188 62.7166C313.691 61.4766 315.326 60.396 317.093 59.4749C318.861 58.5538 320.604 57.8385 322.323 57.3289C325.605 56.3562 328.475 56.1011 330.935 56.5638C333.379 56.9742 335.445 58.1208 337.134 60.0035C338.808 61.8339 340.146 64.4449 341.148 67.8365L348.223 91.786L342.285 93.5462L339.672 84.7021L340.376 84.4937C340.545 86.6 340.238 88.5351 339.456 90.299C338.727 92.0475 337.618 93.5678 336.13 94.86C334.642 96.1523 332.831 97.1149 330.695 97.748Z"
                  fill="white"
                />
                <path
                  d="M380.142 86.1657C378.102 86.4767 376.082 86.5643 374.083 86.4286C372.146 86.3384 370.292 86.0705 368.521 85.6249C366.796 85.1173 365.265 84.4424 363.928 83.6004L365.324 78.6817C366.768 79.5074 368.295 80.1553 369.905 80.6255C371.507 81.0419 373.111 81.2929 374.717 81.3783C376.324 81.4637 377.933 81.3837 379.544 81.1382C382.605 80.6717 384.828 79.755 386.214 78.3879C387.6 77.0209 388.146 75.369 387.852 73.4324C387.623 71.9261 386.933 70.8204 385.782 70.1152C384.623 69.3561 382.905 68.9023 380.63 68.7537L373.054 68.2569C369.565 68.0732 366.857 67.2474 364.93 65.7797C363.058 64.3037 361.913 62.194 361.496 59.4504C361.129 57.0296 361.432 54.8644 362.406 52.9547C363.425 50.983 365.016 49.3371 367.178 48.0169C369.34 46.6966 371.952 45.8033 375.013 45.3368C376.839 45.0586 378.613 44.9809 380.335 45.1037C382.057 45.2266 383.7 45.5541 385.265 46.0863C386.875 46.5565 388.299 47.2477 389.536 48.1599L388.06 53.0909C386.892 52.2781 385.615 51.6471 384.228 51.198C382.833 50.695 381.413 50.3886 379.967 50.2786C378.567 50.1066 377.169 50.127 375.773 50.3398C372.765 50.7981 370.573 51.7376 369.195 53.1585C367.817 54.5793 367.276 56.258 367.57 58.1947C367.798 59.7009 368.47 60.8646 369.584 61.6856C370.697 62.5066 372.307 62.9768 374.413 63.0962L381.977 63.5122C385.582 63.7334 388.371 64.5469 390.343 65.9527C392.36 67.2965 393.581 69.367 394.006 72.1644C394.365 74.5314 394.035 76.7007 393.016 78.6724C391.989 80.5903 390.367 82.2134 388.151 83.5418C385.981 84.8082 383.311 85.6829 380.142 86.1657Z"
                  fill="white"
                />
                <path
                  d="M412.716 81.2657L412.094 23.7259L418.694 23.6543L418.983 50.343L417.923 50.3545C419.03 47.1862 420.851 44.7993 423.387 43.1937C425.977 41.533 428.956 40.6845 432.323 40.6479C435.474 40.6137 438.088 41.1567 440.164 42.277C442.295 43.3966 443.889 45.1207 444.946 47.4492C446.003 49.7232 446.551 52.6286 446.589 56.1653L446.856 80.8952L440.257 80.9668L439.993 56.645C439.967 54.1965 439.619 52.1869 438.95 50.616C438.336 49.0446 437.345 47.8854 435.979 47.1383C434.667 46.3907 432.952 46.0284 430.834 46.0514C428.444 46.0773 426.358 46.6169 424.576 47.6702C422.795 48.7235 421.425 50.1804 420.468 52.041C419.51 53.9016 419.045 56.0833 419.072 58.5862L419.316 81.1941L412.716 81.2657Z"
                  fill="white"
                />
                <path
                  d="M504.904 88.4848C502.249 87.9148 499.966 86.8964 498.058 85.4294C496.214 83.9207 494.875 82.1307 494.04 80.0595C493.205 77.9883 493.027 75.8353 493.505 73.6006C494.119 70.7275 495.32 68.6201 497.107 67.2785C498.947 65.9482 501.614 65.2964 505.109 65.3229C508.656 65.3608 513.351 66.0067 519.194 67.2607L522.78 68.0302L521.875 72.2602L518.369 71.5078C515.129 70.8124 512.397 70.3374 510.174 70.0828C507.951 69.8283 506.145 69.8302 504.756 70.0887C503.367 70.3471 502.316 70.845 501.603 71.5823C500.89 72.3197 500.397 73.3268 500.123 74.6038C499.657 76.7852 500.019 78.7273 501.211 80.4298C502.455 82.1438 504.379 83.2801 506.982 83.8387C509.106 84.2947 511.073 84.1882 512.883 83.5193C514.746 82.8618 516.317 81.752 517.595 80.1899C518.927 78.6393 519.838 76.7201 520.327 74.4322L522.273 65.3338C522.979 62.035 522.821 59.5248 521.8 57.8032C520.791 56.0284 518.773 54.8161 515.745 54.1663C513.408 53.6647 511.076 53.5261 508.751 53.7504C506.436 53.9215 503.978 54.5068 501.375 55.5064L500.151 50.3188C501.737 49.6575 503.48 49.1691 505.381 48.8536C507.294 48.4849 509.246 48.3195 511.237 48.3575C513.229 48.3954 515.102 48.6025 516.855 48.9787C520.201 49.6969 522.844 50.8483 524.783 52.4331C526.733 53.9646 528 55.9617 528.584 58.4244C529.18 60.8339 529.108 63.7678 528.368 67.2263L523.145 91.6482L517.089 90.3486L519.018 81.33L519.735 81.4839C518.878 83.4146 517.685 84.9671 516.156 86.1415C514.68 87.3273 512.98 88.1311 511.056 88.553C509.133 88.975 507.082 88.9522 504.904 88.4848Z"
                  fill="white"
                />
                <path
                  d="M552.573 101.535C548.764 100.249 546.262 98.1415 545.069 95.2124C543.928 92.3006 544.068 88.7305 545.491 84.5021L559.645 42.4245L565.9 44.5356L551.902 86.1491C551.294 87.9539 551.014 89.5533 551.061 90.9472C551.16 92.3586 551.594 93.5387 552.364 94.4876C553.134 95.4365 554.24 96.1542 555.681 96.6407C556.299 96.8492 556.9 97.0233 557.483 97.1628C558.136 97.2682 558.745 97.3304 559.312 97.3495L557.388 102.557C556.513 102.434 555.671 102.293 554.865 102.136C554.109 101.996 553.345 101.795 552.573 101.535Z"
                  fill="white"
                />
                <path
                  d="M583.228 113.936C578.954 112.047 576.253 109.485 575.125 106.251C573.996 103.016 574.364 99.2832 576.228 95.0516L584.616 76.0093L577.534 72.8792L579.606 68.1746L586.688 71.3048L591.589 60.1781L597.628 62.847L592.727 73.9737L604.208 79.0478L602.135 83.7524L590.654 78.6782L582.53 97.123C581.28 99.9607 580.922 102.39 581.457 104.411C582.014 106.381 583.634 107.96 586.318 109.146C587.113 109.498 587.952 109.75 588.835 109.902C589.718 110.054 590.527 110.174 591.26 110.26L590.265 115.351C589.465 115.414 588.378 115.32 587.004 115.07C585.63 114.819 584.372 114.442 583.228 113.936Z"
                  fill="white"
                />
                <path
                  d="M615.625 131.039C612.029 128.899 609.339 126.35 607.556 123.39C605.847 120.412 605.045 117.182 605.151 113.7C605.303 110.247 606.461 106.695 608.625 103.046C610.733 99.4906 613.264 96.8196 616.219 95.0333C619.201 93.2001 622.351 92.2575 625.667 92.2052C629.03 92.1808 632.323 93.1273 635.546 95.0445C638.629 96.8785 640.865 99.1268 642.256 101.79C643.647 104.452 644.191 107.371 643.888 110.545C643.632 113.747 642.478 117.079 640.425 120.541L639.135 122.717L612.302 106.753L614.508 103.034L637.557 116.747L635.896 116.992C638.115 113.249 638.994 109.848 638.533 106.79C638.147 103.712 636.365 101.228 633.189 99.3385C630.807 97.9214 628.443 97.2741 626.096 97.3966C623.823 97.5001 621.684 98.2843 619.678 99.7491C617.7 101.167 615.948 103.163 614.422 105.736L614.172 106.157C612.48 109.011 611.495 111.684 611.218 114.177C610.987 116.698 611.477 119.015 612.688 121.128C613.927 123.194 615.808 124.977 618.33 126.478C620.338 127.672 622.457 128.521 624.686 129.025C626.989 129.509 629.411 129.526 631.953 129.076L631.543 134.243C629.169 134.73 626.542 134.686 623.66 134.111C620.779 133.536 618.101 132.512 615.625 131.039Z"
                  fill="white"
                />
                <path
                  d="M642.895 147.911L667.083 116.501L672.18 120.44L666.456 127.872L665.811 127.374C668.5 125.396 671.245 124.46 674.047 124.563C676.883 124.623 679.867 125.692 683.001 127.77L685.021 129.021L681.925 133.845L678.335 131.69C675.211 129.689 672.223 128.892 669.371 129.299C666.595 129.697 664.128 131.296 661.972 134.097L648.186 151.999L642.895 147.911Z"
                  fill="white"
                />
                <path
                  d="M671.08 170.145L698.99 142.002L703.557 146.546L697.354 152.8L696.719 152.168C699.748 150.734 702.724 150.358 705.646 151.041C708.644 151.724 711.338 153.254 713.728 155.632C715.963 157.856 717.411 160.102 718.071 162.37C718.809 164.638 718.72 166.966 717.806 169.354C716.892 171.743 715.191 174.192 712.703 176.701L695.302 194.247L690.62 189.587L707.733 172.331C709.456 170.594 710.641 168.936 711.289 167.356C711.975 165.815 712.104 164.294 711.677 162.795C711.288 161.334 710.342 159.856 708.839 158.361C707.143 156.673 705.296 155.564 703.297 155.032C701.297 154.499 699.3 154.545 697.305 155.167C695.309 155.789 693.431 156.988 691.67 158.764L675.763 174.804L671.08 170.145Z"
                  fill="white"
                />
                <path
                  d="M714.932 219.021C713.304 216.844 712.251 214.574 711.774 212.212C711.373 209.861 711.537 207.63 712.266 205.519C712.995 203.409 714.273 201.668 716.099 200.298C718.446 198.536 720.734 197.737 722.961 197.901C725.221 198.109 727.659 199.373 730.276 201.692C732.926 204.055 736.042 207.632 739.625 212.422L741.824 215.362L738.368 217.956L736.218 215.081C734.231 212.425 732.483 210.269 730.974 208.614C729.464 206.959 728.101 205.772 726.885 205.053C725.668 204.335 724.55 204.018 723.528 204.105C722.507 204.192 721.475 204.626 720.431 205.409C718.649 206.747 717.648 208.45 717.43 210.517C717.244 212.629 717.949 214.751 719.545 216.885C720.848 218.627 722.401 219.841 724.204 220.528C726.04 221.258 727.952 221.454 729.941 221.118C731.962 220.825 733.907 219.977 735.776 218.575L743.21 212.995C745.906 210.973 747.433 208.976 747.793 207.007C748.196 205.004 747.469 202.762 745.613 200.28C744.179 198.364 742.512 196.725 740.612 195.364C738.755 193.97 736.517 192.793 733.899 191.834L736.379 187.118C738.008 187.663 739.643 188.442 741.283 189.455C742.967 190.435 744.547 191.595 746.024 192.934C747.501 194.274 748.776 195.662 749.851 197.099C751.903 199.842 753.141 202.45 753.563 204.92C754.029 207.358 753.675 209.698 752.5 211.939C751.369 214.148 749.391 216.312 746.565 218.433L726.61 233.409L722.897 228.444L730.266 222.914L730.705 223.502C728.793 224.393 726.875 224.779 724.952 224.658C723.062 224.581 721.253 224.068 719.526 223.121C717.799 222.173 716.268 220.806 714.932 219.021Z"
                  fill="white"
                />
                <path
                  d="M744.133 263.38C741.799 259.326 741.131 255.66 742.129 252.383C743.127 249.106 745.625 246.312 749.626 244.001L767.626 233.604L763.759 226.887L768.206 224.318L772.073 231.035L782.591 224.96L785.888 230.688L775.37 236.763L781.639 247.652L777.192 250.22L770.923 239.331L753.487 249.402C750.805 250.952 749.084 252.7 748.323 254.647C747.61 256.566 747.986 258.799 749.451 261.344C749.885 262.098 750.413 262.798 751.036 263.444C751.658 264.089 752.24 264.664 752.781 265.168L748.974 268.686C748.292 268.263 747.471 267.545 746.51 266.529C745.549 265.514 744.757 264.464 744.133 263.38Z"
                  fill="white"
                />
                <path
                  d="M797.865 257.853L804.579 254.699L808.038 262.089L801.324 265.243L797.865 257.853ZM753.428 279.624L789.284 262.784L792.086 268.77L756.23 285.61L753.428 279.624Z"
                  fill="white"
                />
                <path
                  d="M767.827 313.557L799.636 284.414L801.992 291.198L773.863 316.034L773.14 313.952L810.664 316.176L812.886 322.575L769.861 319.416L767.827 313.557Z"
                  fill="white"
                />
                <path
                  d="M781.716 366.37C780.916 362.257 781.024 358.549 782.042 355.246C783.123 351.986 784.996 349.236 787.659 346.998C790.333 344.813 793.75 343.314 797.909 342.502C801.961 341.711 805.636 341.825 808.934 342.844C812.285 343.853 815.084 345.58 817.33 348.024C819.587 350.521 821.075 353.613 821.792 357.298C822.478 360.823 822.292 363.991 821.234 366.804C820.176 369.616 818.364 371.965 815.798 373.852C813.242 375.793 809.992 377.148 806.046 377.918L803.566 378.402L797.596 347.719L801.835 346.891L806.964 373.249L805.674 372.17C809.94 371.337 813.059 369.73 815.031 367.349C817.066 365.012 817.731 362.027 817.024 358.395C816.494 355.671 815.402 353.473 813.748 351.8C812.158 350.17 810.15 349.093 807.723 348.569C805.35 348.034 802.697 348.053 799.765 348.626L799.285 348.72C796.032 349.355 793.385 350.398 791.343 351.85C789.312 353.356 787.912 355.264 787.144 357.576C786.43 359.878 786.353 362.471 786.914 365.355C787.361 367.651 788.139 369.8 789.248 371.801C790.421 373.845 792.021 375.667 794.048 377.267L789.925 380.401C787.983 378.95 786.267 376.957 784.777 374.42C783.287 371.884 782.267 369.2 781.716 366.37Z"
                  fill="white"
                />
                <path
                  d="M806.823 410.716C806.744 409.315 807.001 408.01 807.591 406.801C808.211 405.59 809.071 404.595 810.173 403.816C811.275 403.037 812.54 402.607 813.967 402.527C815.28 402.453 816.526 402.713 817.705 403.307C818.913 403.927 819.908 404.803 820.687 405.936C821.495 407.066 821.94 408.375 822.024 409.862C822.101 411.234 821.844 412.525 821.253 413.735C820.663 414.972 819.804 415.982 818.673 416.762C817.571 417.541 816.293 417.972 814.837 418.054C813.438 418.132 812.148 417.846 810.966 417.196C809.785 416.574 808.82 415.696 808.069 414.562C807.318 413.428 806.903 412.146 806.823 410.716Z"
                  fill="white"
                />
                <path
                  d="M788.15 460.368L839.651 464.449L841.296 443.619L847.063 444.076L843.24 492.489L837.472 492.032L839.117 471.202L787.617 467.121L788.15 460.368Z"
                  fill="white"
                />
                <path
                  d="M781.004 506.822C781.596 504.167 782.633 501.89 784.114 499.991C785.636 498.158 787.435 496.832 789.51 496.014C791.585 495.196 793.736 495.036 795.963 495.535C798.826 496.176 800.919 497.397 802.242 499.199C803.554 501.053 804.181 503.731 804.124 507.231C804.055 510.784 803.369 515.481 802.065 521.322L801.266 524.907L797.051 523.963L797.833 520.458C798.556 517.219 799.054 514.487 799.328 512.262C799.601 510.038 799.615 508.229 799.37 506.835C799.124 505.442 798.636 504.385 797.906 503.664C797.176 502.943 796.175 502.44 794.903 502.156C792.729 501.669 790.787 502.015 789.077 503.193C787.355 504.425 786.204 506.341 785.623 508.944C785.15 511.068 785.239 513.039 785.89 514.858C786.53 516.729 787.624 518.313 789.172 519.607C790.709 520.954 792.617 521.883 794.896 522.394L803.962 524.423C807.249 525.159 809.756 525.023 811.484 524.016C813.264 523.021 814.492 521.01 815.168 517.983C815.689 515.646 815.848 513.312 815.645 510.981C815.494 508.661 814.932 506.193 813.957 503.577L819.146 502.397C819.792 503.992 820.264 505.742 820.563 507.649C820.914 509.567 821.062 511.524 821.006 513.519C820.951 515.514 820.728 517.387 820.337 519.14C819.59 522.485 818.418 525.122 816.818 527.05C815.272 528.99 813.268 530.242 810.804 530.806C808.393 531.381 805.465 531.283 802.019 530.512L777.685 525.064L779.036 519.01L788.022 521.022L787.862 521.739C785.942 520.863 784.403 519.654 783.244 518.112C782.073 516.624 781.286 514.914 780.881 512.984C780.477 511.053 780.518 508.999 781.004 506.822Z"
                  fill="white"
                />
                <path
                  d="M774.186 538.85L788.698 563.308L785.494 562.104L810.921 553.7L808.173 561.037L788.708 567.234L789.509 565.094L800.074 582.664L797.355 589.924L783.844 566.976L786.667 568.036L759.933 576.908L762.681 569.572L783.587 562.782L782.757 564.998L771.438 546.187L774.186 538.85Z"
                  fill="white"
                />
                <path
                  d="M771.84 595.864L776.671 598.411L766.045 618.635L761.214 616.088L771.84 595.864Z"
                  fill="white"
                />
                <path
                  d="M733.876 624.812L762.523 644.011L766.873 637.499L771.139 640.358L765.793 648.363L765.367 645.917L767.941 647.642C772.32 650.577 774.914 653.887 775.721 657.57C776.499 661.299 775.497 665.539 772.717 670.288L771.085 673.024L766.569 670.685L768.45 667.428C769.553 665.483 770.159 663.696 770.267 662.066C770.375 660.437 769.979 658.927 769.077 657.537C768.175 656.147 766.799 654.832 764.948 653.591L762.103 651.685L763.663 651.551L756.775 661.863L752.509 659.003L758.853 649.506L730.205 630.307L733.876 624.812Z"
                  fill="white"
                />
                <path
                  d="M715.961 650.493L746.601 675.624L742.523 680.613L735.272 674.666L735.789 674.035C737.683 676.784 738.538 679.56 738.354 682.363C738.211 685.2 737.058 688.157 734.893 691.234L733.585 693.221L728.862 689.98L731.117 686.449C733.205 683.379 734.087 680.411 733.763 677.543C733.447 674.752 731.923 672.236 729.191 669.995L711.727 655.671L715.961 650.493Z"
                  fill="white"
                />
                <path
                  d="M679.902 689.422C682.921 686.521 686.091 684.605 689.415 683.673C692.736 682.818 696.058 682.906 699.379 683.937C702.661 685.005 705.769 687.071 708.703 690.133C711.562 693.117 713.456 696.274 714.386 699.603C715.354 702.971 715.421 706.263 714.586 709.478C713.712 712.731 711.923 715.658 709.218 718.257C706.631 720.744 703.871 722.303 700.938 722.935C698.005 723.567 695.052 723.314 692.079 722.174C689.067 721.073 686.169 719.07 683.385 716.164L681.636 714.339L704.155 692.695L707.145 695.816L687.802 714.408L688.009 712.738C691.018 715.88 694.055 717.636 697.121 718.007C700.186 718.455 703.05 717.398 705.716 714.836C707.715 712.915 708.969 710.805 709.477 708.507C709.984 706.285 709.8 704.01 708.926 701.683C708.09 699.395 706.637 697.172 704.568 695.012L704.23 694.659C701.936 692.264 699.626 690.6 697.302 689.667C694.938 688.771 692.578 688.626 690.222 689.231C687.904 689.876 685.686 691.216 683.57 693.25C681.884 694.87 680.502 696.689 679.423 698.706C678.342 700.801 677.68 703.135 677.434 705.708L672.572 703.933C672.738 701.512 673.481 698.988 674.803 696.359C676.125 693.731 677.825 691.419 679.902 689.422Z"
                  fill="white"
                />
                <path
                  d="M641.372 721.537C644.769 719.092 648.178 717.643 651.599 717.191C655.008 716.814 658.284 717.371 661.427 718.861C664.525 720.383 667.311 722.868 669.784 726.315C672.193 729.673 673.623 733.066 674.075 736.493C674.558 739.964 674.16 743.233 672.881 746.298C671.557 749.395 669.373 752.039 666.329 754.23C663.417 756.325 660.465 757.479 657.473 757.689C654.48 757.9 651.592 757.232 648.809 755.683C645.982 754.167 643.396 751.774 641.05 748.504L639.576 746.449L664.92 728.207L667.44 731.72L645.669 747.39L646.11 745.766C648.646 749.301 651.405 751.47 654.388 752.271C657.359 753.148 660.344 752.507 663.344 750.348C665.594 748.728 667.132 746.817 667.96 744.613C668.775 742.486 668.913 740.208 668.376 737.781C667.871 735.397 666.746 732.99 665.003 730.56L664.717 730.162C662.784 727.467 660.732 725.493 658.562 724.24C656.349 723.019 654.033 722.542 651.615 722.808C649.229 723.118 646.845 724.131 644.462 725.845C642.565 727.211 640.941 728.816 639.588 730.66C638.223 732.581 637.238 734.797 636.633 737.31L632.07 734.866C632.574 732.492 633.666 730.098 635.346 727.683C637.025 725.269 639.034 723.22 641.372 721.537Z"
                  fill="white"
                />
                <path
                  d="M575.519 758.92C579.151 757.326 582.634 756.747 585.969 757.185C589.304 757.622 592.314 758.944 595 761.152C597.657 763.431 599.815 766.464 601.472 770.252C603.151 774.09 603.904 777.71 603.732 781.112C603.51 784.537 602.407 787.575 600.424 790.228C598.442 792.881 595.634 795.004 592.002 796.598C589.763 797.581 587.374 798.154 584.836 798.318C582.298 798.482 579.991 798.157 577.916 797.345L578.076 792.017C580.13 792.779 582.169 793.102 584.194 792.986C586.169 792.891 587.928 792.505 589.47 791.828C593.252 790.168 595.639 787.695 596.632 784.407C597.648 781.169 597.185 777.332 595.245 772.897C593.348 768.561 590.823 765.569 587.671 763.923C584.541 762.326 581.085 762.358 577.304 764.018C575.762 764.695 574.286 765.729 572.878 767.119C571.42 768.531 570.286 770.277 569.475 772.356L565.456 768.862C566.266 766.783 567.606 764.829 569.474 762.999C571.315 761.241 573.329 759.881 575.519 758.92Z"
                  fill="white"
                />
                <path
                  d="M538.314 772.602C540.925 771.853 543.417 771.675 545.791 772.069C548.127 772.531 550.149 773.479 551.859 774.913C553.569 776.346 554.738 778.162 555.367 780.359C556.175 783.184 556.105 785.609 555.159 787.635C554.161 789.677 552.114 791.509 549.018 793.134C545.871 794.773 541.425 796.417 535.68 798.065L532.155 799.077L530.966 794.918L534.412 793.929C537.598 793.015 540.231 792.146 542.313 791.322C544.394 790.499 545.986 789.645 547.09 788.763C548.194 787.88 548.887 786.945 549.17 785.958C549.453 784.971 549.415 783.85 549.055 782.595C548.442 780.45 547.21 778.907 545.358 777.967C543.455 777.042 541.224 776.946 538.665 777.68C536.576 778.28 534.89 779.302 533.608 780.745C532.273 782.203 531.409 783.923 531.015 785.904C530.569 787.9 530.667 790.023 531.311 792.272L533.869 801.217C534.797 804.46 536.116 806.601 537.825 807.638C539.55 808.728 541.9 808.846 544.877 807.992C547.175 807.333 549.297 806.356 551.243 805.061C553.205 803.819 555.099 802.143 556.926 800.034L560.443 804.034C559.355 805.365 558.046 806.618 556.517 807.793C555.003 809.02 553.359 810.086 551.584 810.992C549.808 811.898 548.059 812.598 546.336 813.092C543.046 814.036 540.173 814.266 537.718 813.782C535.277 813.351 533.221 812.186 531.548 810.289C529.89 808.444 528.575 805.822 527.602 802.421L520.735 778.411L526.688 776.703L529.224 785.569L528.519 785.772C528.368 783.664 528.691 781.732 529.489 779.974C530.233 778.232 531.355 776.722 532.854 775.443C534.353 774.163 536.173 773.216 538.314 772.602Z"
                  fill="white"
                />
                <path
                  d="M488.8 783.75C490.843 783.456 492.864 783.386 494.861 783.539C496.797 783.646 498.649 783.929 500.416 784.39C502.137 784.912 503.663 785.6 504.992 786.454L503.555 791.36C502.117 790.522 500.596 789.861 498.99 789.377C497.392 788.947 495.79 788.683 494.184 788.583C492.579 788.484 490.969 788.551 489.356 788.782C486.291 789.223 484.06 790.12 482.662 791.475C481.265 792.831 480.705 794.478 480.982 796.417C481.198 797.925 481.879 799.037 483.024 799.752C484.177 800.521 485.89 800.989 488.164 801.157L495.736 801.719C499.223 801.932 501.924 802.781 503.838 804.265C505.698 805.757 506.825 807.877 507.218 810.624C507.565 813.047 507.243 815.21 506.253 817.111C505.217 819.074 503.613 820.706 501.439 822.008C499.266 823.31 496.646 824.181 493.581 824.621C491.753 824.884 489.979 824.946 488.258 824.809C486.537 824.671 484.896 824.33 483.336 823.784C481.731 823.3 480.313 822.597 479.083 821.674L480.602 816.756C481.762 817.579 483.034 818.22 484.417 818.681C485.808 819.196 487.225 819.515 488.67 819.637C490.068 819.821 491.466 819.813 492.864 819.612C495.876 819.179 498.076 818.258 499.466 816.849C500.856 815.44 501.412 813.766 501.134 811.827C500.918 810.319 500.257 809.15 499.15 808.319C498.043 807.489 496.437 807.005 494.332 806.868L486.773 806.387C483.169 806.135 480.388 805.298 478.428 803.875C476.422 802.514 475.219 800.433 474.818 797.632C474.479 795.262 474.827 793.096 475.863 791.133C476.907 789.224 478.542 787.615 480.769 786.305C482.951 785.057 485.628 784.206 488.8 783.75Z"
                  fill="white"
                />
                <path
                  d="M456.103 788.376L456.233 845.919L449.632 845.934L449.572 819.244L450.632 819.242C449.498 822.4 447.656 824.771 445.107 826.355C442.503 827.994 439.517 828.817 436.149 828.824C432.998 828.831 430.389 828.266 428.323 827.128C426.201 825.99 424.622 824.252 423.585 821.915C422.548 819.631 422.025 816.721 422.017 813.185L421.961 788.453L428.562 788.438L428.616 812.762C428.622 815.21 428.952 817.223 429.608 818.799C430.209 820.376 431.189 821.544 432.549 822.302C433.854 823.061 435.566 823.438 437.685 823.434C440.075 823.428 442.166 822.907 443.956 821.869C445.746 820.831 447.128 819.386 448.102 817.533C449.076 815.681 449.56 813.503 449.554 811L449.503 788.391L456.103 788.376Z"
                  fill="white"
                />
                <path
                  d="M364.004 780.361C366.655 780.954 368.928 781.992 370.824 783.476C372.655 785.001 373.979 786.802 374.795 788.881C375.612 790.959 375.771 793.114 375.274 795.344C374.634 798.212 373.415 800.309 371.617 801.634C369.765 802.949 367.092 803.577 363.598 803.52C360.051 803.451 355.362 802.764 349.53 801.459L345.951 800.658L346.893 796.436L350.392 797.219C353.626 797.943 356.354 798.442 358.575 798.716C360.796 798.99 362.602 799.004 363.992 798.757C365.383 798.511 366.439 798.022 367.158 797.291C367.878 796.56 368.38 795.558 368.664 794.283C369.15 792.106 368.804 790.161 367.628 788.448C366.398 786.723 364.485 785.57 361.887 784.988C359.766 784.514 357.798 784.603 355.983 785.256C354.114 785.897 352.534 786.993 351.242 788.544C349.896 790.083 348.969 791.994 348.46 794.278L346.435 803.359C345.7 806.651 345.836 809.163 346.841 810.893C347.835 812.677 349.843 813.907 352.865 814.583C355.198 815.105 357.528 815.264 359.855 815.06C362.171 814.909 364.635 814.346 367.246 813.369L368.425 818.567C366.833 819.214 365.085 819.687 363.182 819.986C361.267 820.338 359.313 820.486 357.322 820.431C355.33 820.376 353.46 820.152 351.71 819.761C348.37 819.013 345.738 817.839 343.813 816.237C341.876 814.689 340.626 812.68 340.063 810.213C339.488 807.798 339.586 804.865 340.356 801.413L345.793 777.038L351.836 778.39L349.829 787.392L349.113 787.232C349.987 785.308 351.194 783.766 352.733 782.605C354.219 781.433 355.926 780.644 357.853 780.239C359.78 779.834 361.831 779.874 364.004 780.361Z"
                  fill="white"
                />
                <path
                  d="M316.492 766.908C320.29 768.227 322.773 770.356 323.941 773.295C325.057 776.217 324.886 779.785 323.427 784.001L308.911 825.955L302.674 823.79L317.031 782.299C317.653 780.499 317.947 778.902 317.912 777.508C317.826 776.096 317.402 774.912 316.64 773.957C315.878 773.001 314.779 772.274 313.342 771.775C312.726 771.561 312.127 771.382 311.544 771.237C310.893 771.126 310.284 771.059 309.717 771.035L311.686 765.844C312.56 765.975 313.4 766.123 314.206 766.287C314.96 766.434 315.722 766.641 316.492 766.908Z"
                  fill="white"
                />
                <path
                  d="M285.921 754.231C290.179 756.157 292.858 758.743 293.958 761.987C295.059 765.231 294.658 768.961 292.758 773.176L284.205 792.145L291.26 795.336L289.147 800.023L282.092 796.831L277.095 807.915L271.079 805.193L276.077 794.11L264.641 788.936L266.754 784.25L278.19 789.423L286.474 771.05C287.749 768.223 288.127 765.797 287.61 763.772C287.07 761.796 285.464 760.204 282.79 758.994C281.998 758.636 281.161 758.377 280.28 758.217C279.398 758.057 278.591 757.93 277.858 757.838L278.898 752.755C279.698 752.699 280.784 752.802 282.156 753.065C283.527 753.327 284.783 753.716 285.921 754.231Z"
                  fill="white"
                />
                <path
                  d="M253.694 736.86C257.272 739.031 259.94 741.603 261.698 744.578C263.381 747.571 264.155 750.808 264.02 754.288C263.838 757.741 262.65 761.282 260.455 764.912C258.316 768.45 255.762 771.099 252.792 772.86C249.794 774.667 246.637 775.583 243.32 775.607C239.957 775.602 236.672 774.628 233.466 772.683C230.399 770.822 228.182 768.555 226.814 765.88C225.446 763.206 224.927 760.283 225.257 757.111C225.54 753.911 226.723 750.589 228.805 747.145L230.114 744.981L256.809 761.174L254.572 764.874L231.64 750.964L233.304 750.733C231.053 754.457 230.145 757.85 230.579 760.912C230.939 763.993 232.699 766.492 235.859 768.409C238.229 769.846 240.588 770.514 242.936 770.412C245.21 770.328 247.356 769.562 249.374 768.114C251.364 766.714 253.133 764.733 254.68 762.173L254.934 761.754C256.65 758.915 257.658 756.25 257.957 753.76C258.21 751.241 257.739 748.92 256.546 746.797C255.325 744.72 253.46 742.921 250.95 741.399C248.952 740.187 246.841 739.319 244.616 738.797C242.317 738.293 239.895 738.255 237.35 738.683L237.804 733.52C240.182 733.054 242.809 733.12 245.685 733.72C248.562 734.32 251.231 735.367 253.694 736.86Z"
                  fill="white"
                />
                <path
                  d="M226.562 719.75L202.107 750.951L197.044 746.969L202.831 739.587L203.471 740.091C200.766 742.044 198.013 742.958 195.212 742.831C192.377 742.746 189.402 741.652 186.286 739.547L184.276 738.278L187.414 733.482L190.985 735.667C194.092 737.695 197.073 738.518 199.928 738.134C202.708 737.76 205.188 736.183 207.368 733.401L221.306 715.617L226.562 719.75Z"
                  fill="white"
                />
                <path
                  d="M198.572 697.279L170.424 725.182L165.896 720.599L172.151 714.398L172.781 715.036C169.739 716.444 166.76 716.794 163.845 716.086C160.852 715.378 158.171 713.825 155.802 711.427C153.586 709.184 152.157 706.925 151.516 704.652C150.798 702.378 150.906 700.051 151.841 697.67C152.775 695.289 154.497 692.855 157.007 690.367L174.556 672.971L179.199 677.67L161.939 694.779C160.201 696.502 159.002 698.15 158.341 699.724C157.641 701.259 157.499 702.779 157.914 704.281C158.29 705.745 159.224 707.232 160.714 708.74C162.395 710.442 164.233 711.567 166.228 712.116C168.222 712.665 170.22 712.637 172.221 712.032C174.221 711.427 176.11 710.244 177.886 708.483L193.929 692.58L198.572 697.279Z"
                  fill="white"
                />
                <path
                  d="M155.134 648.024C156.744 650.216 157.777 652.494 158.234 654.86C158.614 657.214 158.431 659.444 157.684 661.548C156.937 663.653 155.645 665.382 153.807 666.737C151.444 668.479 149.15 669.258 146.925 669.074C144.667 668.847 142.239 667.562 139.642 665.221C137.013 662.835 133.927 659.232 130.385 654.411L128.212 651.453L131.69 648.889L133.815 651.781C135.779 654.454 137.509 656.625 139.004 658.293C140.499 659.961 141.852 661.159 143.062 661.889C144.272 662.618 145.389 662.944 146.411 662.866C147.433 662.788 148.469 662.362 149.519 661.588C151.313 660.266 152.327 658.572 152.563 656.506C152.767 654.397 152.08 652.268 150.503 650.121C149.215 648.368 147.672 647.14 145.875 646.438C144.046 645.692 142.135 645.479 140.144 645.798C138.121 646.074 136.168 646.905 134.287 648.292L126.805 653.807C124.092 655.806 122.548 657.789 122.172 659.756C121.751 661.755 122.459 664.003 124.294 666.501C125.711 668.43 127.364 670.082 129.253 671.46C131.098 672.87 133.325 674.066 135.935 675.047L133.415 679.742C131.79 679.183 130.162 678.39 128.531 677.363C126.856 676.368 125.286 675.195 123.82 673.843C122.355 672.491 121.091 671.092 120.028 669.646C118 666.885 116.785 664.267 116.384 661.793C115.939 659.351 116.313 657.015 117.507 654.783C118.657 652.585 120.653 650.437 123.497 648.341L143.58 633.537L147.25 638.533L139.834 644L139.4 643.408C141.32 642.533 143.241 642.164 145.163 642.302C147.052 642.395 148.857 642.923 150.575 643.885C152.294 644.848 153.814 646.227 155.134 648.024Z"
                  fill="white"
                />
                <path
                  d="M126.318 603.422C128.617 607.496 129.254 611.167 128.228 614.436C127.202 617.704 124.679 620.477 120.659 622.753L102.57 632.994L106.38 639.745L101.911 642.275L98.1014 635.525L87.5318 641.509L84.284 635.753L94.8536 629.769L88.6787 618.826L93.1478 616.296L99.3226 627.239L116.844 617.318C119.54 615.792 121.276 614.059 122.053 612.119C122.783 610.205 122.426 607.97 120.983 605.412C120.555 604.654 120.033 603.949 119.416 603.298C118.799 602.648 118.222 602.068 117.686 601.559L121.522 598.074C122.201 598.503 123.016 599.229 123.968 600.252C124.92 601.276 125.704 602.332 126.318 603.422Z"
                  fill="white"
                />
                <path
                  d="M72.5319 608.468L65.7911 611.562L62.396 604.142L69.1368 601.048L72.5319 608.468ZM117.156 587.082L81.1554 603.611L78.4053 597.6L114.406 581.072L117.156 587.082Z"
                  fill="white"
                />
                <path
                  d="M103.059 553.048L70.9994 581.914L68.7025 575.11L97.0443 550.518L97.7491 552.606L60.2457 550.058L58.0793 543.64L101.075 547.171L103.059 553.048Z"
                  fill="white"
                />
                <path
                  d="M89.6321 500.152C90.3974 504.271 90.2572 507.978 89.2116 511.272C88.1027 514.523 86.2069 517.256 83.5243 519.472C80.8317 521.635 77.4025 523.104 73.2369 523.881C69.178 524.637 65.5041 524.492 62.2152 523.445C58.8729 522.407 56.0888 520.657 53.863 518.194C51.6272 515.677 50.1665 512.573 49.4807 508.882C48.8247 505.351 49.0375 502.184 50.1192 499.381C51.2008 496.578 53.0327 494.244 55.6147 492.379C58.1868 490.461 61.4489 489.133 65.401 488.396L67.8844 487.933L73.5943 518.667L69.3485 519.458L64.4436 493.058L65.7236 494.148C61.4511 494.944 58.3188 496.524 56.3267 498.888C54.2713 501.209 53.5815 504.188 54.2574 507.825C54.7643 510.554 55.8375 512.761 57.4771 514.448C59.0533 516.092 61.0523 517.186 63.4743 517.73C65.8429 518.285 68.4958 518.289 71.4331 517.741L71.9138 517.652C75.1716 517.044 77.8276 516.023 79.8818 514.589C81.9261 513.101 83.3419 511.204 84.1292 508.899C84.8631 506.603 84.9617 504.011 84.425 501.122C83.9976 498.822 83.238 496.667 82.1462 494.656C80.991 492.602 79.4067 490.767 77.3933 489.15L81.5421 486.052C83.4723 487.518 85.1714 489.526 86.6396 492.075C88.1078 494.624 89.1053 497.316 89.6321 500.152Z"
                  fill="white"
                />
                <path
                  d="M65.419 450.421C65.4777 451.823 65.2032 453.125 64.5953 454.326C63.9589 455.528 63.0843 456.51 61.9716 457.274C60.859 458.037 59.5885 458.449 58.1602 458.509C56.8462 458.564 55.604 458.287 54.4338 457.677C53.2339 457.039 52.2522 456.149 51.4889 455.006C50.6971 453.864 50.27 452.549 50.2076 451.061C50.1501 449.687 50.4253 448.4 51.0331 447.199C51.6398 445.97 52.5138 444.973 53.655 444.208C54.7677 443.445 56.0524 443.033 57.5093 442.972C58.909 442.913 60.1952 443.217 61.3678 443.884C62.5392 444.523 63.4923 445.414 64.227 446.559C64.9617 447.703 65.3591 448.991 65.419 450.421Z"
                  fill="white"
                />
              </svg>
            </div>
            <VideoBlockHeading>
              <SubtitleText isCentered variant={"primary"} color="color-1">
                Win this £2.8 million Warwickshire Dream Home
              </SubtitleText>
            </VideoBlockHeading>
          </TourTabpanel>
        </HeroTabpanel>
        <HeroTabpanel id="gallery">
          <GalleryTabpanel>
            {heroGalleryImages.map((imageName, i) => (
              <GalleryItem key={i}>
                <img
                  srcSet={`
                      ${withUrl(`${imageName}_800x450`)} 800w,
                      ${withUrl(`${imageName}_1600x900`)} 1600w,
                      ${withUrl(`${imageName}_3840x2160`)} 3840w,
                    `}
                  sizes={`(max-width: 800px) 800px, (max-width: 1600px) 1600px, 3840px`}
                  src={`${withUrl(`${imageName}_1600x900`)}`}
                  alt="property"
                />
              </GalleryItem>
            ))}
          </GalleryTabpanel>
        </HeroTabpanel>
        <HeroTabpanel id="floorplan">
          <FloorplanTabpanel>
            <picture>
              <source
                srcSet={`${process.env.REACT_APP_AWS_S3_URL}floorplan/01.03.2025/floorplan_desctop.jpg`}
                media="(min-width: 768px)"
              />
              <source
                srcSet={`${process.env.REACT_APP_AWS_S3_URL}floorplan/01.03.2025/floorplan_mobile.jpg`}
              />
              <img
                src={`${process.env.REACT_APP_AWS_S3_URL}floorplan/01.03.2025/floorplan_desctop.jpg`}
                sizes="(min-width: 768px) 1910px, 100vw"
                width={1910}
                height={720}
                alt="floorplan"
              />
            </picture>
          </FloorplanTabpanel>
        </HeroTabpanel>
        <HeroTabpanel id="info">
          <InfoTabpanel>
            {heroInfoData.map(({ title, content }, index) => {
              return (
                <HeroInfoItem
                  title={title}
                  content={content}
                  key={index}
                  renderSubtitle={(string) => HtmlReactParser(string)}
                />
              );
            })}
          </InfoTabpanel>
        </HeroTabpanel>
      </HeroTabpanels>
    </HeroContainer>
  );
}
